/* .bg-right{
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
} */
body{
    line-height: 1.25 !important;
}

.batch-upload-div {
    background-color: #E7F6FC;
    width: 100%;
    border-radius: 5px;
    padding:"50px"!important
}
.batch-upload-div div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.batch-upload-btn {
    color: transparent;
}
.batch-upload-btn::-webkit-file-upload-button {
    visibility: hidden !important;
}
.batch-upload-btn::before {
    content: 'Click here to upload ID Card';
    color: #081630;
    display: inline-block;
    background: transparent;
    margin: 0 auto;
    padding-left: 25%;
    padding-top: 5px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
}

.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
    background-color: #fff !important;
}
.MuiTypography-root.MuiTypography-h3{
    background-image: linear-gradient(310deg, #344767, #344767) !important;
}
.css-l4knm0-MuiButtonBase-root-MuiMenuItem-root {
    margin-top: 5px !important;
}
.css-l4knm0-MuiButtonBase-root-MuiMenuItem-root.Mui-selected, .css-l4knm0-MuiButtonBase-root-MuiMenuItem-root:hover{
    background-color: #08ddb8 !important;
    color:#fff !important;
    border-radius: 0;
}


.form-steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative;

}
.form-steps-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;

}
.form-steps::before {
    position: absolute;
    content: "";
    width: 100%;
    border-bottom: 1px solid #08ddb8;
    bottom: 50%;
    z-index: 1;
}
.form-steps small {
    color: #0e1e57;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
}
.form-steps small.active {
    color: #FFFFFF;
    background-color: #08ddb8;
}
.form-steps-name small {
    color: #0e1e57;
    width: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    font-size: 11px;
    text-align: center;
   /*  align-items: center;
    background-color: #eaeaea;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer; */
}
select{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    padding: 10px;
    border: 0.0625rem solid #d2d6da;
    border-radius: 0.5rem !important;
    color: #0e1e57;

    appearance: none;
}
select:focus, input:focus{
    outline: 1px solid #3acaeb
}
